@font-face {
  font-family: "Montserrat Semi-Bold";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Semi-Bold"), local("Montserrat-SemiBold"),
    url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Bold";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Regular"), local("Roboto-Regular"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

body {
  overflow: hidden;
  height: 100vh;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.1);
  font-family: "Roboto Regular", "Montserrat Regular", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
