@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  border-radius: 5px 0 0 5px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.PageContent-heroImage {
  background-repeat: no-repeat;
  background-size: cover;
  /* width: inherit; */
  height: 100%;
  flex-grow: 2;
  flex-basis: 400px;
}

.PageContent-heroImage-graphic {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  height: 550px;
  width: 550px;
  bottom: -150px;
}

.PageContent-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
}

.PageContent-application {
  background: #f4f4f4;
}

.PageContent-content {
  padding: 40px 96px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 500px;
  overflow: auto;
}

.PageContent-content-noPad {
  padding: 0;
}

.PageContent-body {
  position: relative;
  margin: auto;
}

.Dashboard-decorationImage {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  height: 550px;
  width: 550px;
  bottom: -150px;
  right: 5%;
}
.flex-spacer {
  flex: 1;
}

.Docs-header {
  margin-top: 4em;
  margin-bottom: 2em;
  max-width: 860px;
}

.Docs-card {
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0;
  transition: transform 0.16s ease-out, background-color 0.16s ease-out,
    border-color 0.16s ease-out;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.9);
}

.Docs-card:hover {
  transform: scale(1.01);
  transition: transform 0.16s ease-out, background-color 0.16s ease-out,
    border-color 0.16s ease-out;
  border-color: #258465;
}

.Docs-card-link {
  text-decoration: unset !important;
  cursor: pointer !important;
  width: 100%;
}

.Docs-tee-icon {
  width: 86px;
  margin-right: 16px;
}
